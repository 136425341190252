export const stripHtml = (html) => {
  return html.replace(/<[^>]+>/g, '');
};
export const stripAllHtml = (html) => {
  return html
    .replace(/<[^>]+>/g, '')
    .replace(/&amp;/g, '&')
    .replace(/&nbsp;/g, ' ')
    .replace(/(&#821[67];)|(&[lr]squo;)/g, "'")
    .replace(/&([a-z]+[0-9]*|(#[0-9a-fA-F]+));/g, '')
    .replace(/ +/g, ' ')
    .trim();
};

export const htmlToLDJSON = (str) => str && stripAllHtml(str.replace(/<\/?[b-zB-Z][^>]*>/g, ' ').replace(/"(?=[^<>]*>)/g, "'"));
