import { useEffect, useRef } from 'react';
import { isPrerender } from '@medifind/utils';
import { clearShareBookmarkData, historyInit, historyPop, historyPush, historyReplace } from '@medifind/zustand';
import { listen } from './listen';
import { useRouter } from './useRouter';

const RouterSettings = () => {
  const router = useRouter();

  const previousUrl = useRef(`${router.location.pathname}${router.location.search}`);
  useEffect(() => {
    // initialize history once on mount
    if (!isPrerender) historyInit(`${router.location.pathname}${router.location.search}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    const routeChange = (url, type) => {
      if (!isPrerender) {
        const currentURL = `${router.location.pathname}${router.location.search}`;
        if (type === 'POP') {
          historyPop(currentURL);
        } else if (type === 'PUSH') {
          historyPush(currentURL);
        } else if (type === 'REPLACE') {
          historyReplace(currentURL);
        }
        clearShareBookmarkData();
        previousUrl.current = currentURL;
      }
    };
    const unListen = listen(routeChange);
    return unListen;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return null;
};
export { RouterSettings as default, RouterSettings };
