import { forwardRef, useCallback, useState } from 'react';
import classNames from 'classnames';
import { isFunction } from 'lodash';
import PropTypes from 'prop-types';
import { Skeleton } from '../utils';
import styles from './Button.module.scss';

/*
 * Plan:
 * 1. Text Button
 * 2. Text Button w/ Icon
 * 3. Icon Only Button w/ no background
 * 4. Text Button w/ no background
 */

const Button = forwardRef((props, ref) => {
  let { focus = 'underline' } = props;
  const {
    label,
    icon,
    iconBefore,
    onClick,
    className,
    id,
    color = 'transparent',
    forShow,
    classes = {},
    style,
    type = 'normal',
    iconOnlyAt,
    disabled,
    shape = 'round',
    noPadding,
    noXPadding,
    noWrapping,
    smallXPadding,
    screenReaderText,
    buttonProps,
    btnType = 'button',
    showSkeleton,
    tooltip,
  } = props;
  const [onClickDisabled, setOnClickDisabled] = useState(false);
  if (!label && (icon || iconBefore) && focus === 'underline') {
    focus = 'outline';
  }
  const onRefChange = useCallback(
    (node) => {
      if (ref) {
        if (isFunction(ref)) {
          ref(node.current);
        } else {
          ref.current = node;
        }
      }
    },
    [ref],
  );
  const contents = (
    <span
      style={{ ...(style || {}), ...(style?.content || {}) }}
      className={classNames(styles['focus-container'], className, classes.content, styles[type], {
        [styles[color]]: color,
        [styles[focus]]: !!focus,
        [styles['padding']]: !!label && !noPadding,
        [styles['no-x-padding']]: noXPadding,
        [styles['small-x-padding']]: smallXPadding,
        [styles[shape]]: !!shape,
      })}
      tabIndex="-1"
    >
      {iconBefore && (
        <span className={classNames(styles['icon--before'], { [styles['icon']]: !!label }, classes.iconBefore)}>{iconBefore}</span>
      )}
      {label && iconBefore && (
        <span
          className={classNames(styles['divider'], classes.divider, {
            [`hide--${iconOnlyAt}`]: !!iconOnlyAt,
          })}
        ></span>
      )}
      {label && (
        <span
          className={classNames(styles['label'], classes.label, styles[type], {
            [`hide--${iconOnlyAt}`]: !!iconOnlyAt,
            [styles['no-wrap']]: noWrapping,
          })}
        >
          {label}
        </span>
      )}
      {(label && icon) || (iconBefore && icon) ? (
        <span
          className={classNames(styles['divider'], classes.divider, {
            [`hide--${iconOnlyAt}`]: !!iconOnlyAt && !(iconBefore && icon),
          })}
        ></span>
      ) : null}
      {icon && <span className={classNames({ [styles['icon']]: !!label }, classes.icon)}>{icon}</span>}
    </span>
  );
  const onClickRef = useCallback(
    (e) => {
      if (!(disabled || onClickDisabled || showSkeleton) && onClick) {
        const clickResponse = onClick(e);
        if (clickResponse instanceof Promise) {
          setOnClickDisabled(true);
          clickResponse.finally(() => setOnClickDisabled(false));
        }
      }
    },
    [onClick, disabled, onClickDisabled, showSkeleton],
  );

  return (
    <button
      title={tooltip}
      data-nosnippet
      {...buttonProps}
      {...(screenReaderText || iconOnlyAt ? { 'aria-label': screenReaderText ?? label } : {})}
      style={style?.container}
      className={classNames(styles.button, classes.container, styles[type], {
        [styles[color]]: color,
        [styles[focus]]: !!focus,
        [styles.disabled]: disabled || onClickDisabled || showSkeleton,
        [classes.disabled]: disabled || onClickDisabled || showSkeleton,
        [styles[shape]]: !!shape,
        [styles.skeleton]: showSkeleton,
      })}
      onClick={onClickRef}
      ref={onRefChange}
      {...(id && { id })}
      type={btnType}
      {...(forShow ? { tabIndex: '-1' } : {})}
      {...(disabled || onClickDisabled || showSkeleton ? { disabled: true } : {})}
    >
      {showSkeleton ? <Skeleton>{contents}</Skeleton> : contents}
    </button>
  );
  // }
});
Button.displayName = 'Button';
Button.propTypes = {
  id: PropTypes.string,
  color: PropTypes.oneOf([
    'blue',
    'dark-blue',
    'darkBlue',
    'invalid-blue',
    'invalid-grey',
    'red',
    'invalid-red',
    'light-blue',
    'invalid-light-blue',
    'transparent',
    'transparent-blue',
    'none',
    'inverted-blue',
    'inverted-red',
    'disabled',
    'wide-bordered-light-blue',
    'thin-grey-border',
    'white',
    'white-blue',
    'grey',
  ]),
  type: PropTypes.oneOf(['thin', 'normal', 'juicy', 'none', 'large', 'box', 'capsule']),
  shape: PropTypes.oneOf(['round', 'curved', 'squared']),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  screenReaderText: function (props, propName, componentName) {
    if (!props.screenReaderText && !props.label) {
      return new Error(`One of 'label' or 'screenReaderText' is required by '${componentName}' component.`);
    }
    if (props.screenReaderText) {
      PropTypes.checkPropTypes(
        {
          screenReaderText: PropTypes.string, // or any other PropTypes you want
        },
        props,
        propName,
        componentName,
      );
    }
    return null;
  },
  onClick: PropTypes.func,
  style: PropTypes.object,
  icon: PropTypes.node,
  iconBefore: PropTypes.node,
  classes: PropTypes.shape({
    icon: PropTypes.string,
    iconBefore: PropTypes.string,
    label: PropTypes.string,
    divider: PropTypes.string,
    container: PropTypes.string,
    content: PropTypes.string,
    disabled: PropTypes.string,
  }),
  buttonProps: PropTypes.object,
  noPadding: PropTypes.bool,
  noXPadding: PropTypes.bool,
  noWrapping: PropTypes.bool,
  forShow: PropTypes.bool,
  smallXPadding: PropTypes.bool,
  className: PropTypes.any,
  iconOnlyAt: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl', 'xxl']),
  disabled: PropTypes.bool,
  btnType: PropTypes.string,
  showSkeleton: PropTypes.bool,
  focus: PropTypes.oneOf(['background', 'outline', 'underline', 'force-underline']),
};
export { Button as default, Button };
